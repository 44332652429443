import React from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { createPublication, updatePublication } from "../graphql/mutations";
import { useAuth0 } from "@auth0/auth0-react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MathJax from "mathjax3-react";
import { makeStyles } from "@material-ui/core/styles";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  publicationFormStyle: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: theme.spacing(4),
  },
}));

export default function PublicationForm(props) {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  async function handleSubmit(e) {
    e.preventDefault();
    if (!props.formState.authors || !props.formState.title) return;

    try {
      const publication = { ...props.formState };

      if (publication.id) {
        delete publication._deleted;
        await API.graphql(
          graphqlOperation(updatePublication, { input: publication })
        );
      } else {
        await API.graphql(
          graphqlOperation(createPublication, { input: publication })
        );
      }

      props.setFormState(props.initialFormState);
      await props.fetchPublications();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (prop) => (event) => {
    props.setFormState((prevFormState) => {
      return { ...prevFormState, [prop]: event.target.value };
    });
  };

  return isAuthenticated ? (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.publicationFormStyle}
    >
      <Grid item>
        <FormControl>
          <TextField
            required
            key="publication-authors"
            label="Authors"
            value={props.formState.authors}
            onChange={handleChange("authors")}
          />
          <TextField
            required
            autoFocus
            key="publication-title"
            label="Title"
            value={String.raw`${props.formState.title}`}
            onChange={handleChange("title")}
          />
          <MathJax.Formula formula={String.raw`${props.formState.title}`} />
          <TextField
            key="publication-journal"
            label="Journal"
            value={String.raw`${props.formState.journal}`}
            onChange={handleChange("journal")}
          />
          <TextField
            key="publication-link"
            label="Publication Link"
            placeholder="https://"
            type="url"
            value={String.raw`${props.formState.link}`}
            onChange={handleChange("link")}
          />
          <TextField
            key="publication-arXiv-link"
            label="ArXiv Link"
            placeholder="https://"
            type="url"
            value={String.raw`${props.formState.arXivLink}`}
            onChange={handleChange("arXivLink")}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  ) : null;
}
