import React from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F2B552",
    color: "#FFFFFF",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  title: {
    textAlign: "center",
    fontSize: theme.spacing(4),
    [theme.breakpoints.down("xs")]:{
      fontSize: theme.spacing(3),
    }
  },
}));

export default function Interesting(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="space-between"
    >
      <Grid item>
        <Typography className={classes.title}>Interesting</Typography>
      </Grid>
      <Grid>
        <List>
          <Typography>- Analytic Number Theory</Typography>
          <Typography>- Modular form</Typography>
          <Typography>- Trace formula</Typography>
        </List>
      </Grid>
    </Grid>
  );
}
