import Button from "@material-ui/core/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Image from "../images/avatar.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
    },
    pointerEvents: "none",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

export default function LoginButton() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const classes = useStyles();

  return !isAuthenticated ? (
    <Button className={classes.root} onClick={loginWithRedirect}>
      <Avatar alt="Seiji Kuga" src={Image} className={classes.large} />
    </Button>
  ) : (
    <Button
      className={classes.root}
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      <Avatar alt="Seiji Kuga" src={Image} className={classes.large} />
    </Button>
  );
}
