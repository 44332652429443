import React from "react";
import Box from "@material-ui/core/Box";
import { Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import LoggingInCard from "./util/LoggingInCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F2B552",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]:{
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.up("xs")]:{
      justifyContent: "space-between",
    },
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  button: {
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]:{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    }
  },
}));

export default function Header() {
  const classes = useStyles();
  const isSmallScreen = (window.innerWidth < 600);

  return (
    <Box className={classes.root}>
      <ButtonGroup
        variant="text"
        size="large"
        aria-label="text primary button group"
      >
        <Button className={classes.button} component={Link} to="/">
          Home
        </Button>
        <Button className={classes.button} component={Link} to="/publications">
          { isSmallScreen ? "PUBS" : "Publications"}
        </Button>
        <Button className={classes.button} component={Link} to="/presentations">
          { isSmallScreen ? "PRES" : "Presentations"}
        </Button>
      </ButtonGroup>
      <LoggingInCard />
    </Box>
  );
}
