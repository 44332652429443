import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LogInOutAvatar from "../util/LogInOutAvatar";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      direction: "column",
      alignItems: "center",
      justifyContent: "center",
    }
  },
  avatar: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      alignItem: "center",
      justifyContent: "center",
      margin: "auto",
      padding: theme.spacing(0)
    }
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0)
    }
  },
  name: {
    fontSize: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      margin: "auto"
    }
  },
  otherItem: {
    fontSize: theme.spacing(2),
  },
}));

export default function UserInfo(props) {
  const classes = useStyles();
  const mail = String.fromCharCode(146-31,37+8,112-5,109+8,116-13,82+15,93-29,87+22,109-12,102+14,109-5,43+3,113-6,109+12,119-2,113+2,131-27,108+9,60-15,115+2,62-16,74+23,102-3,36+10,108-2,94+18);

  return (
    <Grid
      container
      className={classes.container}
      spacing={2}
    >
      <Grid item className={classes.avatar}>
        <LogInOutAvatar className={classes.large}/>
      </Grid>
      <Grid item>
        <Typography className={classes.name}>Seiji Kuga</Typography>
        <Typography className={classes.otherItem}>
          Kyushu University Ph.D. student
        </Typography>
        <Typography className={classes.otherItem}>{mail}</Typography>
      </Grid>
    </Grid>
  );
}
