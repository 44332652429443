import React from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { createPresentation, updatePresentation } from "../graphql/mutations";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MathJax from "mathjax3-react";
import { makeStyles } from "@material-ui/core/styles";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: theme.spacing(4),
  },
}));

export default function PresentationForm(props) {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  async function handleSubmit(e) {
    e.preventDefault();
    if (!props.formState.presenter || !props.formState.title) return;

    try {
      const presentation = { ...props.formState };

      if (presentation.id) {
        delete presentation._deleted;
        await API.graphql(
          graphqlOperation(updatePresentation, { input: presentation })
        );
      } else {
        await API.graphql(
          graphqlOperation(createPresentation, { input: presentation })
        );
      }

      props.setFormState(props.initialFormState);
      await props.fetchPresentations();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (prop) => (event) => {
    props.setFormState((prevFormState) => {
      return { ...prevFormState, [prop]: event.target.value };
    });
  };

  return isAuthenticated ? (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid item>
        <FormControl>
          <TextField
            required
            id="presentation-presenter"
            label="Presenter"
            value={props.formState.presenter}
            onChange={handleChange("presenter")}
          />
          <TextField
            id="presentation-date"
            label="Date"
            value={String.raw`${props.formState.date}`}
            onChange={handleChange("date")}
          />
          <TextField
            required
            autoFocus
            id="presentation-title"
            label="Title"
            value={String.raw`${props.formState.title}`}
            onChange={handleChange("title")}
          />
          <MathJax.Formula formula={String.raw`${props.formState.title}`} />
          <TextField
            id="presentation-meeting"
            label="Meeting"
            value={String.raw`${props.formState.meeting}`}
            onChange={handleChange("meeting")}
          />
          <Typography>
            <MathJax.Formula formula={String.raw`${props.formState.meeting}`} />
          </Typography>
          <TextField
            id="presentation-link"
            label="Meeting Link"
            placeholder="https://"
            type="url"
            value={String.raw`${props.formState.link}`}
            onChange={handleChange("link")}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  ) : null;
}
