import React, { useEffect, useState } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { newsByCreatedAt } from "../graphql/queries";
import { deleteNew } from "../graphql/mutations";
import NewForm from "./NewForm";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import MoodRoundedIcon from "@material-ui/icons/MoodRounded";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteButton from "../util/DeleteButton";
import EditButton from "../util/EditButton";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MathJax from "mathjax3-react";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  visual: {
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(4),
    },
  },
  icon: {},
  topic: {
    overflowWrap: "break-word",
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1.5),
      textAlign: "left",
    },
  },
}));

const initialForm = {
  type: "a",
  topic: String.raw`Tex Sample: $\zeta$`,
  link: "",
};

const New = React.memo(({ topic, link }) => {
  const classes = useStyles();

  const open = (event) => {
    event.preventDefault();
    if (link) {
      window.open(link);
    }
  };

  return (
    <Grid item>
      <ListItem>
        <ListItemIcon>
          <MoodRoundedIcon fontSize="large" />
        </ListItemIcon>
        <Typography className={classes.topic}>
          <Link
            component="button"
            target="_blank"
            href="#"
            color="inherit"
            onClick={open}
            rel="noreferrer"
          >
            <Typography className={classes.topic}>
              <MathJax.Formula formula={String.raw`${topic}`} />
            </Typography>
          </Link>
        </Typography>
      </ListItem>
    </Grid>
  );
});

export default function News() {
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [formState, setFormState] = useState(initialForm);

  useEffect(() => {
    fetchNews();
  }, []);

  async function fetchNews() {
    try {
      const newsData = await API.graphql({
        query: newsByCreatedAt,
        variables: { type: "a", sortDirection: "DESC" },
      });
      const news = newsData.data.newsByCreatedAt.items.filter(
        (item) => item._deleted !== true
      );
      setNews(news);
    } catch (error) {
      console.log(error);
    }
  }

  async function removeNew({ id, _version }) {
    try {
      if (!window.confirm("Do you really want to remove this item?")) return;
      const input = { id: id, _version: _version };

      await API.graphql(graphqlOperation(deleteNew, { input: input }));
      const newNewsData = news.filter((item) => item.id !== id);
      setNews(newNewsData);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item>
        <Typography className={classes.visual} variant="h2">
          NEWS
        </Typography>
      </Grid>
      <Grid container item direction="column" alignItems="center">
        <Grid>
          <NewForm
            formState={formState}
            setFormState={setFormState}
            initialForm={initialForm}
            fetchNews={fetchNews}
          />
        </Grid>
        <List>
          {news.map((newItem) => (
            <>
              <New
                className={classes.topic}
                key={newItem.id}
                topic={newItem.topic}
                link={newItem.link}
              />
              <DeleteButton
                key={`delete-new-${newItem.id}`}
                onClick={() => removeNew(newItem)}
              />
              <EditButton
                key={`edit-new-${newItem.id}`}
                onClick={() => setFormState(newItem)}
              />
            </>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
