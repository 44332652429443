import React from "react";
import HomeContent from "./HomeContent";
import MainVisual from "./MainVisual";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      padding: "auto"
    }
  },
}));

export default function Home(props) {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <MainVisual />
      <HomeContent />
    </Container>
  );
}
