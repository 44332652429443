import React, { useEffect, useState, useCallback } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { publicationsByCreatedAt } from "../graphql/queries";
import { deletePublication } from "../graphql/mutations";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Publication from "./Publication";
import PublicationForm from "./PublicationForm";
import DeleteButton from "../util/DeleteButton";
import EditButton from "../util/EditButton";
import { makeStyles } from "@material-ui/core/styles";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  publicationsStyle: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    padding: theme.spacing(4),
  },
  visual: {
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(4),
      textAlign: "center",
    },
  },
}));

const initialFormState = {
  type: "a",
  authors: "Seiji Kuga",
  title: String.raw`Tex Sample: $\zeta$`,
  journal: "",
  link: "",
  arXivLink: "",
};

const PublicationItem = React.memo(({ index, information }) => {
  return <Publication index={index} information={information} />;
});

export default function Publications() {
  const classes = useStyles();
  const [publications, setPublications] = useState([]);
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    fetchPublications();
  }, []);

  async function fetchPublications() {
    try {
      const publicationData = await API.graphql(
        graphqlOperation(publicationsByCreatedAt, {
          type: "a",
          sortDirection: "DESC",
        })
      );
      const publications = publicationData.data.publicationsByCreatedAt.items.filter(
        (item) => item._deleted !== true
      );
      setPublications(publications);
    } catch (error) {
      console.log(error);
    }
  }

  const removePublication = useCallback(
    async ({ id, _version }) => {
      try {
        if (!window.confirm("Do you really want to remove this item?")) return;
        const input = { id: id, _version: _version };

        await API.graphql(
          graphqlOperation(deletePublication, { input: input })
        );
        const newPublicationsData = publications.filter(
          (item) => item.id !== id
        );
        setPublications(newPublicationsData);
      } catch (error) {
        console.log(error);
      }
    },
    [publications]
  );

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.publicationsStyle}
    >
      <Grid item>
        <Typography className={classes.visual} variant="h2">
          PUBLICATIONS
        </Typography>
      </Grid>
      <Grid container item direction="column" alignItems="center">
        <PublicationForm
          formState={formState}
          setFormState={setFormState}
          initialFormState={initialFormState}
          fetchPublications={fetchPublications}
        />
        <List>
          {publications.map((publication, index) => (
            <>
              <PublicationItem
                key={publication.id}
                index={index + 1}
                information={publication}
              />
              <DeleteButton
                key={`delete-publication-${publication.id}`}
                onClick={() => removePublication(publication)}
              />
              <EditButton
                key={`edit-publication-${publication.id}`}
                onClick={() => setFormState(publication)}
              />
            </>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
