import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import { useAuth0 } from "@auth0/auth0-react";

const DeleteButton = React.memo(({ onClick }) => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <IconButton onClick={onClick}>
      <DeleteForeverIcon />
    </IconButton>
  ) : null;
});

export default DeleteButton;
