import React from "react";
import UserInfo from "./UserInfo";
import Interesting from "./Interesting";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    padding: theme.spacing(4),
  },
}));

export default function MainVisual(props) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <UserInfo />
      </Grid>
      <Grid item>
        <Interesting />
      </Grid>
    </Grid>
  );
}
