/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPublication = /* GraphQL */ `
  mutation CreatePublication(
    $input: CreatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    createPublication(input: $input, condition: $condition) {
      id
      type
      authors
      title
      journal
      link
      arXivLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updatePublication = /* GraphQL */ `
  mutation UpdatePublication(
    $input: UpdatePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    updatePublication(input: $input, condition: $condition) {
      id
      type
      authors
      title
      journal
      link
      arXivLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deletePublication = /* GraphQL */ `
  mutation DeletePublication(
    $input: DeletePublicationInput!
    $condition: ModelPublicationConditionInput
  ) {
    deletePublication(input: $input, condition: $condition) {
      id
      type
      authors
      title
      journal
      link
      arXivLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createPresentation = /* GraphQL */ `
  mutation CreatePresentation(
    $input: CreatePresentationInput!
    $condition: ModelPresentationConditionInput
  ) {
    createPresentation(input: $input, condition: $condition) {
      id
      type
      presenter
      title
      meeting
      date
      link
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updatePresentation = /* GraphQL */ `
  mutation UpdatePresentation(
    $input: UpdatePresentationInput!
    $condition: ModelPresentationConditionInput
  ) {
    updatePresentation(input: $input, condition: $condition) {
      id
      type
      presenter
      title
      meeting
      date
      link
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deletePresentation = /* GraphQL */ `
  mutation DeletePresentation(
    $input: DeletePresentationInput!
    $condition: ModelPresentationConditionInput
  ) {
    deletePresentation(input: $input, condition: $condition) {
      id
      type
      presenter
      title
      meeting
      date
      link
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createNew = /* GraphQL */ `
  mutation CreateNew(
    $input: CreateNewInput!
    $condition: ModelNewConditionInput
  ) {
    createNew(input: $input, condition: $condition) {
      id
      type
      topic
      link
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateNew = /* GraphQL */ `
  mutation UpdateNew(
    $input: UpdateNewInput!
    $condition: ModelNewConditionInput
  ) {
    updateNew(input: $input, condition: $condition) {
      id
      type
      topic
      link
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteNew = /* GraphQL */ `
  mutation DeleteNew(
    $input: DeleteNewInput!
    $condition: ModelNewConditionInput
  ) {
    deleteNew(input: $input, condition: $condition) {
      id
      type
      topic
      link
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
