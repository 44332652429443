import React from "react";
import News from "./News.js";
import Grid from "@material-ui/core/Grid";

export default function HomeContent() {
  return (
    <Grid >
      <News />
    </Grid>
  );
}
