import React from "react";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MathJax from "mathjax3-react";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  itemBox: {
    display: "flex",
    direction: "column",
  },
  avatar: {
    margin: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: theme.spacing(2),
      margin: theme.spacing(1),
    },
  },
  textBox: {
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1),
    },
  },
  arXivLink: {
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  arXiv: {
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(1),
    },
  },
  noLink: {
    cursor: "default",
    textDecoration: "none",
  },
}));

export default function Publication(props) {
  const classes = useStyles();

  const open = (event) => {
    event.preventDefault();
    if (props.information.link) {
      window.open(props.information.link);
    }
  };

  const openArXiv = (event) => {
    event.preventDefault();
    if (props.information.arXivLink) {
      window.open(props.information.arXivLink);
    }
  };

  return (
    <Grid item>
      <ListItem>
        <Avatar className={classes.avatar}>{props.index}</Avatar>
        <Box>
          <Link
            component="button"
            target="_blank"
            href="#"
            color="inherit"
            onClick={open}
            rel="noreferrer"
            className={!Boolean(props.information.link) ? classes.noLink : null}
          >
            <Typography className={classes.textBox}>
              <MathJax.Formula
                formula={String.raw`${props.information.authors}`}
              />
            </Typography>
            <Typography className={classes.textBox}>
              <MathJax.Formula
                formula={String.raw`${props.information.title}`}
              />
            </Typography>
            <Typography className={classes.textBox}>
              <MathJax.Formula
                formula={String.raw`${props.information.journal}`}
              />
            </Typography>
          </Link>
          {Boolean(props.information.arXivLink) ? (
            <Link
              component="button"
              target="_blank"
              href="#"
              onClick={openArXiv}
              className={classes.arXivLink}
              rel="noreferrer"
            >
              <Typography className={classes.arXiv}>
                {" "}
                {"(arXiv Link)"}
              </Typography>
            </Link>
          ) : null}
        </Box>
      </ListItem>
    </Grid>
  );
}
