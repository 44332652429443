import React, { useEffect, useState } from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { presentationsByCreatedAt } from "../graphql/queries";
import { deletePresentation } from "../graphql/mutations";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Presentation from "./Presentation";
import PresentationForm from "./PresentationForm";
import DeleteButton from "../util/DeleteButton";
import EditButton from "../util/EditButton";
import { makeStyles } from "@material-ui/core/styles";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    padding: theme.spacing(4),
  },
  visual: {
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(4),
      textAlign: "center",
    },
  },
}));

const PresentationItem = React.memo(({ index, information }) => {
  return <Presentation index={index} information={information} />;
});

const initialFormState = {
  type: "a",
  presenter: "Seiji Kuga",
  title: String.raw`Sample: $\zeta$`,
  meeting: String.raw``,
  date: "2021/04/01",
  link: "",
};

export default function Publications() {
  const classes = useStyles();
  const [presentations, setPresentations] = useState([]);
  const [formState, setFormState] = useState(initialFormState);

  useEffect(() => {
    fetchPresentations();
  }, []);

  async function fetchPresentations() {
    try {
      const presentationData = await API.graphql({
        query: presentationsByCreatedAt,
        variables: { type: "a", sortDirection: "DESC" },
      });
      const presentations = presentationData.data.presentationsByCreatedAt.items.filter(
        (item) => item._deleted !== true
      );
      setPresentations(presentations);
    } catch (error) {
      console.log(error);
    }
  }

  async function removePresentation({ id, _version }) {
    try {
      if (!window.confirm("Do you really want to remove this item?")) return;
      const input = { id: id, _version: _version };

      await API.graphql(graphqlOperation(deletePresentation, { input: input }));
      const newPresentationsData = presentations.filter(
        (item) => item.id !== id
      );
      setPresentations(newPresentationsData);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid item>
        <Typography className={classes.visual} variant="h2">
          PRESENTATIONS
        </Typography>
      </Grid>
      <Grid container item direction="column" alignItems="center">
        <PresentationForm
          formState={formState}
          setFormState={setFormState}
          initialFormState={initialFormState}
          fetchPresentations={fetchPresentations}
        />
        <List>
          {presentations.map((presentation, index) => (
            <>
              <PresentationItem
                key={presentation.id}
                index={index + 1}
                information={presentation}
              />
              <DeleteButton onClick={() => removePresentation(presentation)} />
              <EditButton onClick={() => setFormState(presentation)} />
            </>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}
