import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import { useAuth0 } from "@auth0/auth0-react";

export default function EditButton(props) {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <IconButton onClick={props.onClick}>
      <EditIcon />
    </IconButton>
  ) : null;
}
