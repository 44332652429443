import SchoolIcon from "@material-ui/icons/School"
import { useAuth0 } from "@auth0/auth0-react";

export default function LoggingInCard() {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <SchoolIcon color="primary" fontSize="large"/>
    ) : null;
}
