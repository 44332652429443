import React from "react";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import { createNew, updateNew } from "../graphql/mutations";
import { useAuth0 } from "@auth0/auth0-react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MathJax from "mathjax3-react";
import { makeStyles } from "@material-ui/core/styles";

import awsExports from "../aws-exports";
Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    padding: theme.spacing(4),
  },
  submitButton: {
    margin: theme.spacing(4),
  },
}));

export default function NewForm(props) {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  async function handleSubmit(e) {
    e.preventDefault();
    if (!props.formState.topic) return;

    try {
      const newArticle = { ...props.formState };
      if (newArticle.id) {
        delete newArticle._deleted;
        await API.graphql(graphqlOperation(updateNew, { input: newArticle }));
      } else {
        await API.graphql(graphqlOperation(createNew, { input: newArticle }));
      }

      props.setFormState(props.initialForm);
      await props.fetchNews();
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (prop) => (event) => {
    props.setFormState((prevFormState) => {
      return { ...prevFormState, [prop]: event.target.value };
    });
  };

  return isAuthenticated ? (
    <Grid
      container
      display="flex"
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid item>
        <FormControl>
          <TextField
            required
            id="new-topic"
            label="Topic"
            value={String.raw`${props.formState.topic}`}
            onChange={handleChange("topic")}
          />
          <MathJax.Formula formula={String.raw`${props.formState.topic}`} />
          <TextField
            id="presentation-link"
            label="News Link"
            placeholder="https://"
            type="url"
            value={String.raw`${props.formState.link}`}
            onChange={handleChange("link")}
          />
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          className={classes.submitButton}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  ) : null;
}
