import * as React from "react";
import Header from "./Header";
import Home from "./home/Home";
import Publications from "./publications/Publications";
import Presentations from "./presentations/Presentations";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Image from "./images/itoshima.svg";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Reset } from "styled-reset";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    minHeight: "100vh",
    backgroundRepeat: "repeat",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Reset />
      <Box className={classes.root}>
        <Router>
          <Header />
          <Box>
            <Route exact path="/" component={Home} />
            <Route exact path="/publications" component={Publications} />
            <Route exact path="/presentations" component={Presentations} />
          </Box>
        </Router>
      </Box>
    </React.Fragment>
  );
}

export default App;
